import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	Code,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import { Ul } from "../list/list";
import { Paragraph } from "../paragraph/paragraph";
import { TextContainer } from "../text-container/text-container";

import { ExternalHtml } from "./external-html";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "External HTML",
	notepad: "https://hackmd.io/ikdacX2pRwueiBpmejRbSg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="External HTML"
			subTitle="Use this component to add correct typographic styling to textual HTML elements. Translation keys containing HTML elements are the primary use case."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={ExternalHtml} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					In some cases, such as when using translation keys, it can be technically difficult or
					impossible to use our typography components (e.g. headings, paragraphs, or links).
					Instead, regular HTML elements are used, such as <Code>{"<h1>"}</Code>,{" "}
					<Code>{"<Paragraph>"}</Code>, or <Code>{"<a>"}</Code>. By wrapping these HTML elements in
					the external HTML element, they will automatically receive the same styling as that our
					our typography components.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					Here you can see an example of how the external HTML component applies the same styling to
					a <Code>{"<Paragraph>"}</Code> with an <Code>{"<a>"}</Code> inside it, as that of our{" "}
					<GatsbyLink to="../Paragraph">paragraph</GatsbyLink> and{" "}
					<GatsbyLink to="../../Navigation/Link">link</GatsbyLink> components.
				</Paragraph>
				<Example fn={basicUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={ExternalHtml}
					initialProps={{
						children: `
<p>
	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet ullamcorper nunc ullamcorper fermentum.
	Curabitur accumsan nunc vitae pharetra dictum. Proin eu ante vel sem lobortis varius at id enim.
	Orci varius natoque penatibus et magnis dis parturient montes, <a href="#default">nascetur ridiculus mus</a>.
</p>`,
					}}
				/>

				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								Use when it’s technically challenging or impossible to use our typographic
								components (<GatsbyLink to="../Paragraph">paragraph</GatsbyLink>,{" "}
								<GatsbyLink to="../Heading">heading</GatsbyLink>,{" "}
								<GatsbyLink to="../List">list</GatsbyLink>, etc.)
							</>,
							<>
								When the external HTML component contains multiple block-level HTML elements (e.g.
								an <Code>{"<Header.h3>"}</Code> and a <Code>{"<p>"}</Code>), wrap it in a{" "}
								<GatsbyLink to="../Text container">text container</GatsbyLink> component. The text
								container component will automatically add the correct spacing between the
								typographic elements.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						Using our typographic components (<GatsbyLink to="../Paragraph">paragraph</GatsbyLink>,{" "}
						<GatsbyLink to="../Heading">heading</GatsbyLink>,{" "}
						<GatsbyLink to="../List">list</GatsbyLink>, etc.) presents no technical challenges.
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required. However, be
						aware that the accessibility guidelines for our typography components, such as{" "}
						<GatsbyLink to="../Paragraph">paragraph</GatsbyLink>,{" "}
						<GatsbyLink to="../Heading">heading</GatsbyLink>, and{" "}
						<GatsbyLink to="../../Navigation/Link">link</GatsbyLink>, also apply to their HTML
						counterparts used inside the external HTML component.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>
						There are no writing guidelines specifically for this component. However, the writing
						guidelines for our typography components, such as{" "}
						<GatsbyLink to="../Paragraph">paragraph</GatsbyLink>,{" "}
						<GatsbyLink to="../Heading">heading</GatsbyLink>, and{" "}
						<GatsbyLink to="../../Navigation/Link">link</GatsbyLink>, also apply to their HTML
						counterparts used inside the external HTML component.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 0.0.x</Header.H3>
				<Paragraph>
					This component no longer has a <Code>textContainer</Code> property. Instead, the{" "}
					<GatsbyLink to="../Text container">text container</GatsbyLink> component should be used to
					add vertical spacing between block-level HTML elements.
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);

const basicUsage = () => (
	<ExternalHtml>
		{`
		<p>
		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet ullamcorper nunc ullamcorper fermentum.
		Curabitur accumsan nunc vitae pharetra dictum. Proin eu ante vel sem lobortis varius at id enim.
		Orci varius natoque penatibus et magnis dis parturient montes, <a href="#default">nascetur dolor mus</a>.
		</p>
		`}
	</ExternalHtml>
);
